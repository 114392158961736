<template>
	<SelectWithChips
		v-model="filters.statuses"
		:disabled="statuses.length === 0"
		:label="$t('client_workspace.task.status')"
		:items="statuses"
		:item-value="'id'"
		:item-text="'name'"
		:item-short-text="itemShortText"
		list-with-chips
		:minimized="isMinimized"
		multiple
		:prepend-icon="$vuetify.breakpoint.mdAndDown ? null : 'play_arrow'"
		:max-selected-items-showed="minimized ? 0 : 1"
	/>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerFilterStatus',
	components: {
		SelectWithChips: () => ({
			component: import('@/components/Commons/SelectWithChips')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			filters: state => state.tasks.filters,
			statuses: state => state.tasks.statuses
		}),
		isMinimized: function () {
			return this.minimized || this.$vuetify.breakpoint.lgAndDown
		},
		itemShortText: function () {
			return this.isMinimized ? 'abbreviation' : null
		}
	},
	created: function () {
		this.statuses.forEach(status => {
			status.abbreviation = this.$t(status.name)
				.split(' ')
				.map(part => part[0].toUpperCase())
				.join('')
		})
	}
}
</script>
